<template>
  <v-container class="px-4" id="menu-order-details-payment-other-tip">
    <other-tip :is-delivery="$route.meta.isDelivery ? true : false" />
  </v-container>
</template>

<script>
import OtherTip from "@/components/menu/order/payment/OtherTip";

export default {
  name: "MenuOrderDetailsPaymentCheckout",

  provide: {
    heading: { align: "start" },
  },

  components: {
    OtherTip,
  },
};
</script>
