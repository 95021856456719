<template lang="">
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" class="py-0">
        <v-form ref="form" v-model="validForm" lazy-validation class="pa-0">
          <v-text-field
            v-model="tip"
            required
            class="pb-4"
            :rules="rules"
            single-line
            label="Ingresar Monto"
            hint="Monto minimo $30 y máximo $500"
            persistent-hint
            type="number"
            @keydown.enter.prevent="validateFormAndSend"
          ></v-text-field>
          <v-btn
            @click="validateFormAndSend"
            :disabled="!validForm"
            color="info"
            class="px-5"
            block
            depressed
            x-large
            rounded
          >
            ACEPTAR
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: {
    isDelivery: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      tip: undefined,
      loading: false,
      validForm: true,
      rules: [
        (v) => !!v || "Necesario",
        (v) => {
          if (v && !v.trim()) return true;
          if (!isNaN(parseFloat(v)) && v >= 30 && v <= 500) return true;
          return "Monto minimo $30 y máximo $500";
        },
      ],
    };
  },
  methods: {
    validateFormAndSend() {
      if (this.$refs.form.validate()) {
        if (this.isDelivery) {
          this.storeDeliveryCartTip();
        } else {
          this.storeOrderTip();
        }
      }
    },
    async storeOrderTip() {
      this.$store.dispatch("order/addNewCurrentTip", this.tip);
      this.$router.push({
        path: "/menu/order/details/payment",
        query: this.$route.query,
      });
    },
    async storeDeliveryCartTip() {
      this.$store.dispatch("deliveryCart/addNewCurrentTip", this.tip);
      this.$router.push({
        path: "/menu/weborders/payment",
        query: this.$route.query,
      });
    },
  },
};
</script>
<style lang="sass" scoped>
::v-deep .no-active
  background-color:  var(--v-primary-base) !important
  opacity: 1 !important
  > span
    color: white
</style>